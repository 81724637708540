import Thumbnails from "../../../../src/components/thumbnails";
import mujiNotebook from "../../../../content/blog/2021-11-02-how-i-work/notebook-cover.png";
import notes1 from "../../../../content/blog/2021-11-02-how-i-work/notebook-1.jpeg";
import notes2 from "../../../../content/blog/2021-11-02-how-i-work/notebook-2.jpeg";
import notes3 from "../../../../content/blog/2021-11-02-how-i-work/notebook-3.jpeg";
import bearApp from "../../../../content/blog/2021-11-02-how-i-work/bear-app.png";
import notionApp from "../../../../content/blog/2021-11-02-how-i-work/notion.png";
import notionApp2 from "../../../../content/blog/2021-11-02-how-i-work/notion-2.png";
import sketchApp from "../../../../content/blog/2021-11-02-how-i-work/sketch.png";
import rhythmischApp from "../../../../content/blog/2021-11-02-how-i-work/rhythmisch-app.png";
import * as React from 'react';
export default {
  Thumbnails,
  mujiNotebook,
  notes1,
  notes2,
  notes3,
  bearApp,
  notionApp,
  notionApp2,
  sketchApp,
  rhythmischApp,
  React
};