import Thumbnails from "../../../../src/components/thumbnails";
import herokuClassic from "../../../../content/blog/2021-12-07-a-more-festive-heroku/heroku-classic.png";
import herokuFestive from "../../../../content/blog/2021-12-07-a-more-festive-heroku/heroku-festive.gif";
import herokuFirelog from "../../../../content/blog/2021-12-07-a-more-festive-heroku/heroku-firelog.gif";
import * as React from 'react';
export default {
  Thumbnails,
  herokuClassic,
  herokuFestive,
  herokuFirelog,
  React
};