import React from 'react';

export default function Thumbnails({ width, thumbnails, center }) {
  width = width ?? 350;

  return (
    <div className={`thumbnails ${center ? "center" : "" }`}>
      {thumbnails.map(({ src, alt, caption }) => <figure className="thumbnail" style={{ maxWidth: width }}>
        <a href={src} target="_blank">
          <img src={src} alt={alt}/>
        </a>
        { caption ? <figcaption>{caption}</figcaption> : null}
      </figure>)}
    </div>
  );
}